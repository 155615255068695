.bottom{
  padding: 0;
  margin: 0;
  height: 100px;
  position: fixed;
  z-index: -1;
  bottom: 0;
  left: 0;
  background-color: transparent;
//  background: radial-gradient(#ececec66, #efefef66);
}
@media (max-width: 768px)
{
  .bottom{

    z-index: 1 !important;
    background: linear-gradient(#ededed, #ebebeb);
  }
}
