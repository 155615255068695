.bottom{
  padding: 0;
  margin: 0;
  height: 100px;
  position: fixed;
  z-index: -1;
  bottom: 0;
  left: 0;
  background: linear-gradient(#efefef66, #ececec66);
  .container{
    display: none;
  }
}

.bottombig{
  animation: bigbottom 0.3s ease;
  height: 300px ;
  background: #ffffff;
  z-index: 4;
  .container{
    display: flex;
    margin: auto;
    padding: 0;
    .content{
      padding: calc(10px + 1vw);
      padding-left: calc(20px + 3vw);
      padding-right: calc(20px + 3vw);
      margin: 0;
      max-height: 215px;
      overflow: scroll;

      .choosefoil{
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 90px;
        max-width: 130px;
        margin-bottom: 10px;
        margin-top: 10px;
        .foilzone{
          border: 1px solid black;
          margin: 0;
          max-height: 90px;
          max-width: 110px;
          cursor: pointer;
          p{
            font-family: 'Poppins';
            font-weight: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.3rem;
            padding-bottom: 0;
            margin-bottom:5px;

          }
        }
        .foilzone:hover{
          background-color: #EFEFEF;

        }
      }
    }
    .close{
      display: flex;
      justify-content: flex-end;
      padding: 0;
      margin: 0;
      .closebtn{
        margin: 0;
        margin-top: 65px;
        position: absolute;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        img{
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
.paperselect{
  overflow: visible;
  top: 58px;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 0;
}
.paperimg {
  height: 100% !important;
  width: 100% !important;
  padding: 0 !important;
}
.papertext{
  font-family: 'Poppins';
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  position: absolute;
  top: 90px;
  margin-top:8px;
  width: 85px;
  text-align: center;
  overflow: visible;
  line-height: 18px;
}
@keyframes bigbottom {
  0%   {height: 100px;}
  100% {height: 300px ;}
}



//2ème possibilité de design avec vue 3D plus grande

.choixselectionne{
  animation: choixselectionne 0.2s ease;
  background: linear-gradient(0deg,  #efefef66, #ececec66, #ffffff, #e8e8e8);
  background-size: 200% 200%;
  z-index: 4;
  background-position:50% 100%;
  .container{
    display: flex;
    margin: auto;
    padding: 0;
    .content{
      padding: 0;
      padding-left: calc(20px + 3vw);
      padding-right: calc(20px + 3vw);
      margin: 0;
      width: 100%;
      height: 100px;
      max-height: 100px;
      overflow: visible;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .position{
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 50px;
        overflow: visible;
        margin: 0;
    }
    }
    .close{
      display: flex;
      justify-content: flex-end;
      padding: 0;
      margin: 0;
      .closebtn{
        margin: 0;
        margin-top: 65px;
        position: absolute;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        img{
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
@keyframes choixselectionne {
  0%{background-position:0% 50%}
  100%{background-position:50% 100%}
 // 0%   {  background: linear-gradient(#efefef66, #ececec66);}
 // 100% {  background: linear-gradient(#ffffff, #e8e8e8);}
}

@keyframes showpaper {
  0%   {top: 0px;}
  100% {top: -70px;}
}
.scroll{
  transform: rotate(-90deg);
  transform-origin: right top;
  overflow: scroll;
  height: 45vw;
  width: 150px;
  /* width: 100%; */
  position: absolute;
  overflow: scroll;
  top: 0;
  display: block;
  margin: 0;
  animation: showpaper 0.3s ease;
  top: -80px;
  left: -40px;
  .choosefoil{
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 130px;
    width: 120px;
    padding-right: 0px;
    padding-left: 0px;
    margin-left: 15px;
    transform: rotate(90deg) scale(1);
    .foilzone{
      border: 1px solid #656565;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.13);
      padding: 0;
      cursor: pointer;
      background-color: #f3f3f3;
     img{
      padding: 10px;
      height: 100%;
      width: 100%;
     }
    }
    .blackfoilzone {
      border: 1px solid black;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.13);
      padding: 0;
      cursor: pointer;
      background-color: #656565;
      
    }

  }
  .choosefoil:hover{
    animation: selectedfoil 0.3s ease;
    transform: rotate(90deg) scale(1.034);
  }
}
// Appler cette class à la place de choosefoil lors d'un clic sur un foil
.selectedfoil{
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 120px;
  width: 160px;
  padding-right: 20px;
  padding-left: 20px;
  transform: rotate(90deg) scale(1.084) !important;
  animation: choixselectionne 0s ease !important;
  .foilzone{
    border: 1px solid #000000 !important;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.22) !important;
    padding: 0;
    cursor: pointer;
    background-color: #f3f3f3;
   
  }
  .blackfoilzone {
    border: 1px solid #f3f3f3;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.13);
    padding: 0;
    cursor: pointer;
    background-color: #656565;
    
  }
  .foiltext{
    bottom: -3px !important;
    border: 1px solid #656565;
    border-radius: 100px;
    font-weight: 400 !important;
  
  }
  .papertext{
    top: 87px;
    border: 1px solid #656565;
    border-radius: 100px;
    font-weight: 400 !important;
  
  }
}



@keyframes selectedfoil {
  0%   {transform: rotate(90deg) scale(1);}
  100% {transform: rotate(90deg) scale(1.034);}
}
.foiltext{
  font-family: 'Poppins';
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  position: absolute;
  margin-top:8px;
  width: 85px;
  bottom: -5px;
}

@media (min-width: 576px), (max-height: 576px)
{
  .selectedfoil{
    .foiltext{
      bottom: 1px !important;
    
    }
  }

  .foiltext{
    bottom: -1px;
  }
}

.arrowleft{
  position: absolute;
  left: 45px;
  top: 8px;
  cursor: pointer;
  img{
    width: 30px;
  }

}
.arrowright{
  position: absolute;
  right: calc(22.5vw - 250px);
  top: 8px;
  transform: rotate(180deg);
  cursor: pointer;
  img{
    width: 30px;
  }
}
@media (max-width: 1450px)
{
  .scroll{
    height: 592px;
  }
  .arrowright{
    right: 90px;
  }
}
@media (max-width: 1200px)
  {
    .scroll{
      height: 472px;
      left: -55px;
    }
    .arrowright{
      right: 100px;
    }
    .arrowleft{
      left: 28px;;
    }
}
@media (max-width: 992px)
  {
    .scroll{
      height: 272px;
      left: -57px;
    }
    .arrowright{
      right: 130px;
    }
    .arrowleft{
      left: 45px;;
    }
}
@media (max-width: 768px)
  {
    .scroll{
      height: 150px;
      left: -55px;
    }
    .arrowright{
      right: 130px;
    }
    .arrowleft{
      left: 35px;;
    }
}
@media (max-width: 576px), (max-height: 576px)
  {
    .scroll{
      height: calc(100vw - 130px);
      left: -85px;
      
    }
    .arrowright{
      right: 20px;
      top: 100px;
    }
    .arrowleft{
      left: 20px;
      top: 100px;
    }
}




@media (max-width: 576px), (max-height: 576px)
{
  .bottom{
    height: 0;
    bottom: 200px;
    .container{
      height: 200px;
      background-color: #F7F7F7;
      padding: 0;
      margin: 0;
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      bottom: 100px;
      .content{
        padding: 0;
        padding-left: 0;
        padding-right: 0;
        margin: 0;
        width: 100%;
        height: 200px;
        max-height: 200px;
        overflow: visible;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        flex: 0 0 100%;
        max-width: 100%;
        left: 0;
        .scroll{

          top: 35px;
          .choosefoil{
            width: 100px;
            height: 120px;
            margin-top: -5px;
            .foilzone{
              height: 75px;
              img{
                padding: 0;
              }
            }
          }
          .selectedfoil{
            transform: rotate(90deg) scale(1.100) !important;
          }

        }

      }
      .close{
          display: none;
        }
    }
  }
}

@media (min-width: 576px), (max-height: 576px)
{
  .choosetitle{
    display: none;
  }
}
@media (max-width: 576px), (max-height: 576px)
{
  .choosetitle{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
    left: 0;
    padding-left: 30px;
    padding-right: 30px;
    height: fit-content;
    height: 50px;
    align-items: center;
    position: absolute;
    z-index: 10;
    top: 0;
    p{
      font-size: 2.2rem;
      font-weight: 500;
      font-family: 'Poppins';
      margin: 0;
      padding: 0;
      width: 80%;
      height: fit-content;
    }
    img{
      width: 18px;
      cursor: pointer;
    }
  }

  @keyframes choixselectionne {
    0%{background-position:0% 50%}
    100%{background-position:50% 100%}
   // 0%   {  background: linear-gradient(#efefef66, #ececec66);}
   // 100% {  background: linear-gradient(#ffffff, #e8e8e8);}
  }
  
  @keyframes showpaper {
    0%   {top: 150px;}
    100% {top: 50px;}
  }
  .clickchoose{
    .container{
      display: none;
    }

  }
}
