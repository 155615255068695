/*//////////////////////////////////////////////////////////////////
[ FONT ]*/


/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    font-family: "Poppins", sans-serif;
}

/*---------------------------------------------*/
a {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a:focus {
    outline: none !important;
}

a:hover {
    text-decoration: none;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

p {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
}

ul,
li {
    margin: 0px;
    list-style-type: none;
}


/*---------------------------------------------*/
input {
    outline: none;
    border: none;
}

input[type="number"] {
    -moz-appearance: textfield;
    appearance: none;
    -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

textarea {
    outline: none;
    border: none;
}

textarea:focus,
input:focus {
    border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

input:focus:-moz-placeholder {
    color: transparent;
}

input:focus::-moz-placeholder {
    color: transparent;
}

input:focus:-ms-input-placeholder {
    color: transparent;
}

textarea:focus::-webkit-input-placeholder {
    color: transparent;
}

textarea:focus:-moz-placeholder {
    color: transparent;
}

textarea:focus::-moz-placeholder {
    color: transparent;
}

textarea:focus:-ms-input-placeholder {
    color: transparent;
}

input::-webkit-input-placeholder {
    color: #999999;
}

input:-moz-placeholder {
    color: #999999;
}

input::-moz-placeholder {
    color: #999999;
}

input:-ms-input-placeholder {
    color: #999999;
}

textarea::-webkit-input-placeholder {
    color: #999999;
}

textarea:-moz-placeholder {
    color: #999999;
}

textarea::-moz-placeholder {
    color: #999999;
}

textarea:-ms-input-placeholder {
    color: #999999;
}

/*---------------------------------------------*/
button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}




/*//////////////////////////////////////////////////////////////////
[ Contact ]*/

.container-contact100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
   /* justify-content: center; */
    align-items: center;
    padding: 15px;
    background: rgba(0, 0, 0, 0.096);
    position: absolute;
    z-index: 4000;
    backdrop-filter: blur(1px);

}

.contact100-map {
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.wrap-contact100 {
    width: 460px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    z-index: 4001;
    opacity: 0.9;
    right: 5vw;

}
@media (max-width: 768px) {

    .container-contact100 {
        justify-content: center;
    }
    .wrap-contact100 {

        right: inherit;
    
    }
}

@media (max-width: 576px) {
    .wrap-contact100{
            width: inherit;
        }
        .contact100-form {
            padding: 5px 15px 0px 15px !important;
        }
}

/*==================================================================
[ Title form ]*/
.contact100-form-title {
    width: 100%;
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    padding: 25px 15px 25px 15px;
}

.contact100-form-title-1 {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    line-height: 1.2;
    text-align: center;
    padding-bottom: 7px;
}

.contact100-form-title-2 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    line-height: 1.5;
    text-align: center;
}


.contact100-form-title::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(54, 84, 99, 0.7);
}


/*==================================================================
[ Form ]*/

.contact100-form {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 17px 55px 0 135px;
}


/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #b2b2b2;
    margin-bottom: 24px;
}

.label-input100 {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 15px;
    color: #808080;
    line-height: 1.2;
    text-align: right;

    position: absolute;
    top: 14px;
    left: -105px;
    width: 80px;

}

/*---------------------------------------------*/
.input100 {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 15px;
    color: #555555;
    line-height: 1.2;

    display: block;
    width: 100%;
    background: transparent;
    padding: 0 5px;
}

.focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}

.focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 1px;

    -webkit-transition: all 0.6s;
    -o-transition: all 0.6s;
    -moz-transition: all 0.6s;
    transition: all 0.6s;

    background: #004C92;
}


/*---------------------------------------------*/
input.input100 {
    height: 35px;
}
.checkbox{
    height: 25px;
    width: 19px;
    margin-left: 5px;
}

.wrap-input100_agree {
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;
    border-bottom: none;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

textarea.input100 {
    min-height: 115px;
    padding-top: 14px;
    padding-bottom: 13px;
}

.labelagree{
    width: calc(100% - 50px);
    margin: 0;
    padding: 0;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 13px;

}
.labelagree a {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 13px;
    text-decoration: underline;
}
.input100:focus + .focus-input100::before {
    width: 100%;
}

.has-val.input100 + .focus-input100::before {
    width: 100%;
}


/*------------------------------------------------------------------
[ Button ]*/
.container-contact100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    justify-content: space-between;
    padding: 10px 60px 27px 60px;

}
.wrap-input100_agree{
    padding: 3px 60px 0px 90px;
    margin-bottom: 15px;
}

.contact100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 150px;
    height: 45px;
    background-color: #004C92;
    border-radius: 25px;

    font-family: "Poppins";
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    line-height: 1.2;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}
.contact100-form-btn-retour{
    background-color: transparent !important;
    border: 1px solid rgba(95, 95, 95, 0.74) !important;
    color: rgb(43, 43, 43) !important;
    margin-right: 20px !important;
}
.contact100-form-btn i {
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.contact100-form-btn:hover {
    background-color: #333333;
}
.contact100-form-btn-retour:hover {
    background-color: #e7e7e7 !important;
}

.contact100-form-btn:hover i {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
}


/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 576px) {
    .contact100-form {
        padding: 43px 15px 57px 117px;
    }

    .wrap-input100_agree {
        padding: 0px 0px 5px 0px;
    
        padding-left: 15px;
        padding-right: 15px;
    }

    .container-contact100-form-btn {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        padding-top: 4px;
        align-items: center;
        justify-content: space-between;
        padding: 0px 0px 30px 0px;
    
        padding-left: 25px;
        padding-right: 25px;
    }

    .labelagree {
        width: calc(100% - 45px);
    }
    
}

@media (max-width: 480px) {
    .contact100-form {
        padding: 43px 15px 57px 15px;
    }

    .label-input100 {
        text-align: left;
        position: unset;
        top: unset;
        left: unset;
        width: 100%;
        padding: 0 5px;
    }
}

@media (max-width: 371px) {
    .container-contact100-form-btn {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        padding-top: 4px;
        align-items: center;
        justify-content: space-between;
        padding: 0px 0px 30px 0px;
    
        padding-left: 2px;
        padding-right: 2px;
    }
    .wrap-input100_agree{
        padding: 0px 15px 0px 15px;
        margin-bottom: 10px;
    }

    .contact100-form-title {

        padding: 25px 15px 15px 15px;
    }
}
@media (max-width: 371px)
{
    .contact100-form {
        padding: 5px 15px 0px 15px !important;
    }
    .wrap-input100 {
        margin-bottom: 17px;
    }
}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
    position: relative;
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 2px;
    pointer-events: none;

    font-family: Poppins-Medium;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;

    visibility: hidden;
    opacity: 0;

    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}

.alert-validate::after {
    content: "\f06a";
    font-family: FontAwesome;
    display: block;
    position: absolute;
    color: #c80000;
    font-size: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 8px;
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1;
    }
}


.contact100-map {
    background-color: #004C92;
}

.imageform {
    padding-left: 55px;
    padding-right: 50px;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 7px;
    height: 65px;

}

.imageform img {
    height: 50%;
}

.imageform .littleimg {
    height: 100%;
}

@media (max-width: 576px) {
    .imageform {
        height: 75px;
        padding-left: 15px;
        padding-right: 15px;
    }

}

@media (max-width: 350px) {
    .imageform {
        padding-left: 15px !important;
        height: 60px;
    }
    .contact100-form-btn {

        min-width: 130px;
        height: 45px;

    }
}
