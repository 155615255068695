/* =================================================

Name:  Ctatlogue CSS
Author: MGI Digital Technology, MGI Labs
Version: 1.0
Design and Developed by: MGI Labs

NB: Ce fichier contient le style de l'écran de création du Catalogue.

=================================================== */
::-webkit-scrollbar {
  display: none;
}

:root{
  --blue:#0051A3;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 200;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Work sans";
  src: local("Work_sans"),
    url(../../../fonts/Work_Sans/static/WorkSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Work sans";
  font-weight: 200;
  src: local("Work_sans"),
    url(../../../fonts/Work_Sans/static/WorkSans-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Work sans";
  font-weight: 300;
  src: local("Work_sans"),
    url(../../../fonts/Work_Sans/static/WorkSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Work sans";
  font-weight: 500;
  src: local("Work_sans"),
    url(../../../fonts/Work_Sans/static/WorkSans-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Work sans";
  font-weight: 600;
  src: local("Work_sans"),
    url(../../../fonts/Work_Sans/static/WorkSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Work sans";
  font-weight: 700;
  src: local("Work_sans"),
    url(../../../fonts/Work_Sans/static/WorkSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Work sans";
  font-weight: 800;
  src: local("Work_sans"),
    url(../../../fonts/Work_Sans/static/WorkSans-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Work sans";
  font-weight: 900;
  src: local("Work_sans"),
    url(../../../fonts/Work_Sans/static/WorkSans-Black.ttf) format("truetype");
}

html {
  overflow: hidden;
  height: 100%;
  width: 100%;
  font-size: 62.5%;
}
body {
  height: 100%;
  width: 100%;
}
a {
  color: black;
  text-decoration: none;
}
a:hover {
  color: black;
  text-decoration: none;
  text-transform: none;
}
.home {
  height: 100%;
  width: 100%;
  padding: 0;
  background-color: white;
 // background: radial-gradient(
 //   rgba(245, 245, 245, 0.3),
  //  rgba(203, 203, 203, 0.3)
 // );
  .container {
    height: 100%;
    padding: 0 !important;
    margin-right: 0;
    .principal {
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }
}

.bottle_button {
  position: fixed;
  top: 150px;
  right: calc(3vw + 30px);
  z-index: 100;
  cursor: pointer;
  img {
    height: 80px;
    width: 80px;
    opacity: 0.1;
  }
  img:hover {
    opacity: 0.3;
  }
}

@media (max-width: 600px) {
  .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.choosetype {
  padding: 0;
  margin: 0;
  height: 100%;
  position: absolute;
  flex: 0 0 44.445%;
  max-width: 44.445%;
}


.content {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
  flex: 0 0 100%;
  max-width: 100%;
}
.filtershare {
  padding: 0;
  margin: 0;
  height: 100%;
}
@keyframes padding0 {
  0%   {padding-left: 50px;}
  100% {padding-left: 0px;}
}
@keyframes padding30 {
  0%   {padding-left: 0px;}
  100% {padding-left: 50px;}
}
@keyframes devisnotvisible {
  0%   {width: calc(100% - 450px);}
  100% {width: 100%;}
}
@keyframes devisvisible {
  0%   {width: 100%;}
  100% {width: calc(100% - 450px);}
}
.divisvisible{
  width: calc(100% - 450px);
  animation: devisvisible 0.4s ease !important;
  @media (min-width: 841px) and (max-width: 1400px)
  {
    .container{
      animation: padding30 0.2s ease;
      padding-left: 50px;
    }
  }

}
.devinotvisible{
  width: 100%;
  animation: devisnotvisible 0.2s ease;
  @media (min-width: 841px) and (max-width: 1400px)
  {
    .container{
      animation: padding0 0.2s ease;
      padding-left: 0px;
    }
  }

}

// ________

.landing{
  width: 55.5%;
  height: 100%;
  box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.041);
  background-color: white;
  z-index: 10;
}
.catalogue{
  height: 100%;
  width: 44.445%;
  background-color: #F8F8F8;
}
@media (max-width: 768px) 
{
  .landing{
    width: 100%;
    height: 100%;
    box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.014);
    background-color: white;
    z-index: 10;
  }
  .catalogue{
    height: 100%;
    width: 0%;
    background-color: #F8F8F8;
    display: none;
  }
}




//------ BOOTSTRAP
.container, .container-lg, .container-md, .container-sm, .container-xl {
  max-width: 100% !important;
}


/*
  @media (min-width: 576px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 548px;
    }
    }
    @media (min-width: 768px){
      .container, .container-lg, .container-md, .container-sm, .container-xl {
          max-width: 744px;
      }
      }
      @media (min-width: 992px){
        .container, .container-lg, .container-md, .container-sm, .container-xl {
            max-width: 975px;
        }
        }
    @media (min-width: 1200px){
      .container, .container-lg, .container-md, .container-sm, .container-xl {
          max-width: 1170px;
      }
      }

      @media (min-width: 1400px){
        .container, .container-lg, .container-md, .container-sm, .container-xl {
            max-width: 92.5% !important;
        }
        }

        */