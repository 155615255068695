/* =================================================

Name:  Ctatlogue CSS
Author: MGI Digital Technology, MGI Labs
Version: 1.0
Design and Developed by: MGI Labs

NB: Ce fichier contient le style de l'écran de création du Catalogue.

=================================================== */
::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 200;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: local("Poppins"),
    url(../../../fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

html {
  overflow: hidden;
  height: 100%;
  width: 100%;
  font-size: 62.5%;
}
body {
  height: 100%;
  width: 100%;
}
a {
  color: black;
  text-decoration: none;
}
a:hover {
  color: black;
  text-decoration: none;
  text-transform: none;
}
.home {
  height: 100%;
  width: 100%;
  padding: 0;

  background: radial-gradient(
    rgba(245, 245, 245, 0.3),
    rgba(203, 203, 203, 0.3)
  );
  .container {
    height: 100%;
    padding: 0;
    .principal {
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }
}

.bottle_button {
  position: fixed;
  top: 150px;
  right: calc(3vw + 30px);
  z-index: 100;
  cursor: pointer;
  img {
    height: 80px;
    width: 80px;
    opacity: 0.1;
  }
  img:hover {
    opacity: 0.3;
  }
}

@media (max-width: 600px) {
  .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.choosetype {
  padding: 0;
  margin: 0;
  height: 100%;

}
.content {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
  flex: 0 0 100%;
  max-width: 100%;
}
.filtershare {
  padding: 0;
  margin: 0;
  height: 100%;
}
@keyframes padding0 {
  0%   {padding-left: 50px;}
  100% {padding-left: 0px;}
}
@keyframes padding30 {
  0%   {padding-left: 0px;}
  100% {padding-left: 50px;}
}
@keyframes devisnotvisible {
  0%   {width: calc(100% - 450px);}
  100% {width: 100%;}
}
@keyframes devisvisible {
  0%   {width: 100%;}
  100% {width: calc(100% - 450px);}
}
.divisvisible{
  width: calc(100% - 450px);
  animation: devisvisible 0.4s ease !important;
  @media (min-width: 841px) and (max-width: 1400px)
  {
    .container{
      animation: padding30 0.2s ease;
      padding-left: 50px;
    }
  }

}
.devinotvisible{
  width: 100%;
  animation: devisnotvisible 0.2s ease;
  @media (min-width: 841px) and (max-width: 1400px)
  {
    .container{
      animation: padding0 0.2s ease;
      padding-left: 0px;
    }
  }

}


@media (max-width: 576px)
{
  .choosetype {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 0px;
  }
  .container {
    padding-left: 0 !important;
    padding-right: 0px !important;
  }
  .filtershare{
    width: 0px;
  }

  .bottle_button {
    position: fixed;
    top: 70px;
    right: calc(20px);
    z-index: 100;
    cursor: pointer;
    img {
      height: 55px;
      width: 55px;
      opacity: 0.35;
    }
    img:hover {
      opacity: 0.5;
    }
  }
}