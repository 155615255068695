.allpage {
  height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  .container {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    padding-top: 40px;
  }
}
.content {
  background-color: #F8F8F8;
  height: 100%;
  padding: 22px calc(20px + 2.5vw) 28px;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: scroll;
}
@media (min-width: 992px) and (max-width: 1200px)
{
  .content{
    padding-top: 50px;
    padding-bottom: 70px;
  }
}
@media (min-height: 1100px)
{
  .content{
    padding-top: calc( 20px + 10vh);
    padding-bottom: calc( 20px + 10vh);
  }
}
.projet {
 // height: 33.333%;
  max-height: 31.7%;
  overflow: hidden;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px 0px #00000026;

  .image {
    padding: 0;
    cursor: pointer;
    top: 0;
    margin: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.projet:hover {
  /* Permet de réaliser l'animation agrandire */
  animation: crescendo 0.3s ease;
  transform: scale(1.037);
  box-shadow: 0px 4px 6px 0px #0000003b;
  .image {
    /* Permet de réaliser l'animation zoom sur l'image */
    a {
      height: 100%;
      width: 100%;
      img {
        animation: crescendoimg 1.4s ease-out;
        transform: scale(1.03) !important;
      }
    }
  }
}
@keyframes crescendo {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.037);
  }
}
@keyframes crescendoimg {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.03);
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  .projet:last-child {
   // display: none;
  }
  .projet {
    height: 22%;
    max-height: 21%;
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 48%;
  }
}
@media (max-width: 576px) {
  .projet {
    height: auto;
    max-width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
@media (min-width: 992px) {
  .projet {
 //   max-width: 31.7%;
    max-width: 47%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .projet {
    flex: 0 0 50%;
    max-width: 48%;
  }
}

@media (max-width: 768px) {
  .content {
    height: calc(100% - 100px);
  }
}
