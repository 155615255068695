.allpage{
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  .container{
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
.content{
  height: 100%;
  padding: 0;
  padding-top: 26px;
  padding-bottom: 20px;
  margin: 0;
  display: block;
}
.infoentreprise{
  margin: 0;
  padding: 0;
  height: fit-content;
  width: 200px;
  .logoentreprise{
    margin: 0;
    padding: 0px;
    //  border: 1px solid rgba(170, 170, 170, 0.178);

    max-height: 45px;
    min-height: 40px;
    min-width: 50px;
    display: flex;
    align-items: center;
    //   justify-content: center;
    background-color: transparent;
    z-index: 3;
    a{
      height: 100%;
      max-width: 190px;
      max-height: 100%;
    }

    img{
      height: 100%;
      max-width: 190px;
      max-height: 100%;


   //  margin: auto;
   //  filter: invert(1);    
  // width: 110px;
    }
  }
  @media (max-width: 1200px) {
    .logoentreprise {
      margin: 0;
      padding: 0px;
      //  border: 1px solid rgba(170, 170, 170, 0.178);
  
      max-height: 45px;
      min-height: 35px;
      min-width: 45px;
      display: flex;
      align-items: center;
  
      //   justify-content: center;
      background-color: transparent;
      a{
        height: 100%;
        max-width: 160px;
        max-height: 100%;
      }
      img {
        // width: 75%;
        //  min-width: 100px;
        // max-width: 198px;
        height: 100%;
        max-width: 160px;
        max-height: 100%;
  
        // filter: invert(1);
        //     width: 110px;
      }
    }
  }
  @media (max-width: 992px) {
    .logoentreprise {
      margin: 0;
      padding: 0px;
      //  border: 1px solid rgba(170, 170, 170, 0.178);
  
      max-height: 40px;
      min-height: 30px;
      min-width: 45px;
      display: flex;
      align-items: center;
  
      //   justify-content: center;
      background-color: transparent;
      a{
        height: 100%;
        max-width: 135px;
        max-height: 100%;
      }
      img {
        // width: 75%;
        //  min-width: 100px;
        // max-width: 198px;
        height: 100%;
        max-width: 135px;
        max-height: 100%;
  
        // filter: invert(1);
        //     width: 110px;
      }
    }
  }
  @media (max-width: 768px) {
    .logoentreprise {
      margin: 0;
      padding: 0px;
      //  border: 1px solid rgba(170, 170, 170, 0.178);
  
      max-height: 35px;
      min-height: 30px;
      min-width: 45px;
      display: flex;
      align-items: center;
  
      //   justify-content: center;
      background-color: transparent;
      z-index: 3;
      a{
        height: 100%;
        max-width: 135px;
        max-height: 100%;
      }
      img {
        // width: 75%;
        //  min-width: 100px;
        // max-width: 198px;
        height: 100%;
        max-width: 135px;
        max-height: 100%;
  
        // filter: invert(1);
        //     width: 110px;
      }
    }
  }
}


.titlezone{
  height: calc(100% - 270px);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  .nameentreprise{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    p{
      writing-mode: vertical-lr;
      text-orientation: mixed;
      transform: rotate(180deg);
      font-family: 'Poppins';
      font-size: calc(0.3vw + 1rem);
      padding: 0;
      margin: 0;
      color: #CFCFCF;
    }
  }
}


.choose{
  height: auto;
  position: absolute;
  bottom: 107px;
        //Rajout 3D
        z-index: 5;
        position: absolute;
        //__
  padding: 0;
  margin: 0;
  .choosetype{
    height: 100%;
    padding: 0;
    margin: 0;
    .titlechange{
      height: 33%;
      display: flex;
      align-items: center;
      color: black;
      text-decoration: none;
      padding: 0;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;

      .title{
        font-weight: 500;
        color: black;
        font-size: calc(0.7vw + 1.7rem);
        height: calc(25px + 0.7vw);
      margin-bottom: 0.7vw;
      text-overflow: ellipsis;
      overflow: hidden;
      }
      img{
        display: none;
      }
    }
    .change{
      margin-top: 3px !important;
      height: 33%;
      display: flex;
      align-items: center;
      color: black;
      text-decoration: none;
      padding: 0;
      margin: 0;
      height: 70%;
      display: flex;
      flex-wrap: wrap;
      

      .typeselected{
        position: relative;
        h2{
          color: #565656;
          font-family: 'Poppins';
          font-size: calc(0.65vw + 1.15rem) !important;
          font-weight: 500 !important;
          padding: 0;
          margin: 0;

        }
        .viewseparation{
          width: 1px;
          height: 70%;
          position: absolute;
          right: 0;
          background-color: #BCBCBC;
        }
        .changeimg{
          transform: scale(1.12) !important;
        }
      }
      .papertype{
        max-width: 100% !important;
        padding: 0 !important;
        margin-left: 5px !important;
        left: 0;
      }
      .typezone{
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .type{
        max-width: calc(100% - 50px);
        padding: 0;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        padding-left: 7px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 10px;
        left: -7px;
        /* margin-bottom: 5px; */
        /* margin-top: 5px; */
        height: 45px;
        .silvercolor{
          position: absolute;
          left: calc(-15px - 0.5vw);
          width: 12px;
          height: 65%;
          border: 1px solid #AAAAAA;
        }
        .gold{
          background-color: gold;
        }
        .silver{
          background-color: silver;
        }
        .red{
          background-color: red;
        }
        .blue{
          background-color: blue;
        }
        .green{
          background-color: green;
        }
        h2{
          color: #565656;
          font-family: 'Poppins';
          font-size: calc(0.6vw + 1rem);
          font-weight: 300;
          

        padding: 0;
        margin: 0;
        }
        .imgpaper{
          border: 1px solid #707070;
          height: 25px;
          width: 40px;
        }
        .viewseparation{
          width: 1px;
          height: 70%;
          position: absolute;
          right: 0;
          background-color: #BCBCBC;
        }
        .changeimg{
          transform: scale(1.12) !important;
        }
      }
      .typeColorVarnish{
        max-width: calc(100% - 50px);
        padding: 0;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        padding-left: 7px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 10px;
        left: -7px;
        /* margin-bottom: 5px; */
        /* margin-top: 5px; */
        height: 45px;
        .silvercolor{
          position: absolute;
          left: calc(-15px - 0.5vw);
          width: 12px;
          height: 65%;
          border: 1px solid #AAAAAA;
        }
        .gold{
          background-color: gold;
        }
        .silver{
          background-color: silver;
        }
        .red{
          background-color: red;
        }
        .blue{
          background-color: blue;
        }
        .green{
          background-color: green;
        }
        h2{
          color: #565656;
          font-family: 'Poppins';
          font-size: calc(0.6vw + 1rem);
          font-weight: 300;
          

        padding: 0;
        margin: 0;
        }
        .imgpaper{
          border: 1px solid #707070;
          height: 25px;
          width: 40px;
        }
        .viewseparation{
          width: 1px;
          height: 70%;
          position: absolute;
          right: 0;
          background-color: #BCBCBC;
        }
        .changeimg{
          transform: scale(1.12) !important;
        }
      }
      .separation{
        height: 1px;
        background-color: #D0D0D0;
        width: 50%;
        margin-top: 5%;
      }
      .type:hover{
        cursor: pointer;
        margin-top: 5px;
        margin-bottom: 5px;
        h2{
          color: #565656;
          font-family: 'Poppins';
          font-size: calc(0.6vw + 1.1rem);
          font-weight: 500;
        }
        .changeimg{
         
          animation: rotateanim 0.3s ease;
          transform: rotate(180deg);
        }
        .imgpaper{
          border: 1px solid #707070;
          transform: scale(1.03);
        }
      }
      .typeselected:hover{
        cursor: pointer;
        margin-bottom: 5px ;
        margin-top: 5px;
        h2{
          color: #565656;
          font-family: 'Poppins';
          font-size: calc(0.6vw + 1.1rem);
          font-weight: 500;
        }
        .changeimg{
         
          animation: rotateanim 0.3s ease;
          transform: rotate(180deg);
        }
        .imgpaper{
          border: 1px solid #707070;
        }
      }
    }
  }
}
.view{
  position: absolute;
  right: 0px;
  width: 27,33px;
  cursor: pointer;
}
.white{
  color: white !important;
}

@keyframes rotateanim {
  0%   {transform: rotate(0deg);}
  100% {transform: rotate(180deg);}
  }
  .contactzone{
    position: absolute;
    bottom: 0;
    padding: 0;
    margin: 0;
    height: 100px;
    @media (max-width: 1450px)
    {
      .contact{
        width: 170px !important;
      }
    }
    @media (max-width: 1200px)
      {
        .contact{
         width: 150px !important;
      }
    }

    .contact{
      height: max-content;
      position: absolute;
      bottom: 30px;
      left: 0;
      z-index: 5;
      cursor: pointer;
      width: 200px;
      display: flex;
      justify-content: flex-start;
      .contactus{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 1.4rem;
        img{
          position: absolute;
          height: 40px;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  @keyframes devisnotvisible {
    0%   {left: 0px;}
    100% {left: -450px;}
  }
  @keyframes devisvisible {
    0%   {left: -450px;}
    100% {left: 0px;}
  }
  .divisvisible{
    left: 0px;
    animation: devisvisible 0.4s ease;
  }
  .devinotvisible{
    left: -450px;
    animation: devisnotvisible 0.2s ease;
  }


@media (max-width: 576px)
{
  .infoentreprise{
    position: fixed;
    left: 25px;
    top: 15px;
    z-index: 15;
    .logoentreprise {
      margin: 0;
      padding: 0px;
      //  border: 1px solid rgba(170, 170, 170, 0.178);
  
      max-height: 35px;
      min-height: 30px;
      min-width: 45px;
      display: flex;
      align-items: center;
  
      //   justify-content: center;
      background-color: transparent;
      z-index: 3;
      a{
        height: 100%;
        max-width: 135px;
        max-height: 100%;
      }
      img {
        // width: 75%;
        //  min-width: 100px;
        // max-width: 198px;
        height: 100%;
        max-width: 135px;
        max-height: 100%;
  
        // filter: invert(1);
        //     width: 110px;
      }
    }
 }
 .contact{
  display: none !important;

}
}

@media (max-width: 576px)
{
  .white{
    color: black !important;
  }
  .choose{
    height: auto;
    position: fixed;
    bottom: 0;
          //Rajout 3D
          z-index: 5;

          //__
          background-color: #F7F7F7;
    padding: 0;
    margin: 0;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    left: 0;
    .choosetype{
      height: 100%;
      padding: 0;
      margin: 0;
      .titlechange{
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: black;
        text-decoration: none;
        padding: 0;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
  
        .title{
          font-weight: 500;
          color: black;
          font-size: calc(2.2rem);
          height: calc(25px + 0.7vw);
        margin-bottom: 0.7vw;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 80%;
        }
        img{
          width: 27px;
          cursor: pointer;
          display: block;
        }
      }

      .change{
        margin-top: 3px !important;
        height: 33%;
        display: flex;
        align-items: center;
        color: black;
        text-decoration: none;
        padding: 0;
        margin: 0;
        height: 70%;
        display: flex;
        flex-wrap: wrap;
        
  
        .typeselected{
          position: relative;
          h2{
            color: #565656;
            font-family: 'Poppins';
            font-size: calc(1.9rem) !important;
            font-weight: 500 !important;
            padding: 0;
            margin: 0;
  
          }
          .viewseparation{
            width: 1px;
            height: 70%;
            position: absolute;
            right: 0;
            background-color: #BCBCBC;
          }
          .changeimg{
            transform: scale(1.12) !important;
          }
        }
        .papertype{
          max-width: 100% !important;
          padding: 0 !important;
          margin-left: 5px !important;
          left: 0;
        }
        .typezone{
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .view{
            filter: invert(0.5);
          }
        }
        .type{
          max-width: calc(100% - 50px);
          padding: 0;
          margin-bottom: 0 !important;
          margin-top: 0 !important;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 8px;
          padding-left: 7px;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-right: 10px;
          left: -7px;
          /* margin-bottom: 5px; */
          /* margin-top: 5px; */
          height: 45px;
          .silvercolor{
            position: absolute;
            left: calc(-15px - 0.5vw);
            width: 12px;
            height: 65%;
            border: 1px solid #AAAAAA;
          }
          .gold{
            background-color: gold;
          }
          .silver{
            background-color: silver;
          }
          .red{
            background-color: red;
          }
          .blue{
            background-color: blue;
          }
          .green{
            background-color: green;
          }
          h2{
            color: #565656;
            font-family: 'Poppins';
            font-size: calc(1.8rem);
            font-weight: 300;
            
  
          padding: 0;
          margin: 0;
          }
          .imgpaper{
            border: 1px solid #707070;
            height: 25px;
            width: 40px;
          }
          .viewseparation{
            width: 1px;
            height: 70%;
            position: absolute;
            right: 0;
            background-color: #BCBCBC;
          }
          .changeimg{
            transform: scale(1.12) !important;
          }
        }
        .typeColorVarnish{
          max-width: calc(100% - 50px);
          padding: 0;
          margin-bottom: 0 !important;
          margin-top: 0 !important;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 8px;
          padding-left: 7px;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-right: 10px;
          left: -7px;
          /* margin-bottom: 5px; */
          /* margin-top: 5px; */
          height: 45px;
          .silvercolor{
            position: absolute;
            left: calc(-15px - 0.5vw);
            width: 12px;
            height: 65%;
            border: 1px solid #AAAAAA;
          }
          .gold{
            background-color: gold;
          }
          .silver{
            background-color: silver;
          }
          .red{
            background-color: red;
          }
          .blue{
            background-color: blue;
          }
          .green{
            background-color: green;
          }
          h2{
            color: #565656;
            font-family: 'Poppins';
            font-size: calc(1.8rem);
            font-weight: 300;
            
  
          padding: 0;
          margin: 0;
          }
          .imgpaper{
            border: 1px solid #707070;
            height: 25px;
            width: 40px;
          }
          .viewseparation{
            width: 1px;
            height: 70%;
            position: absolute;
            right: 0;
            background-color: #BCBCBC;
          }
          .changeimg{
            transform: scale(1.12) !important;
          }
        }
        .separation{
          height: 1px;
          background-color: #D0D0D0;
          width: 50%;
          margin-top: 5%;
        }
        .type:hover{
          cursor: pointer;
          margin-top: 5px;
          margin-bottom: 5px;
          h2{
            color: #565656;
            font-family: 'Poppins';
            font-size: calc(1.9rem);
            font-weight: 500;
          }
          .changeimg{
           
            animation: rotateanim 0.3s ease;
            transform: rotate(180deg);
          }
          .imgpaper{
            border: 1px solid #707070;
            transform: scale(1.03);
          }
        }
        .typeselected:hover{
          cursor: pointer;
          margin-bottom: 5px ;
          margin-top: 5px;
          h2{
            color: #565656;
            font-family: 'Poppins';
            font-size: calc(1.9rem);
            font-weight: 500;
          }
          .changeimg{
           
            animation: rotateanim 0.3s ease;
            transform: rotate(180deg);
          }
          .imgpaper{
            border: 1px solid #707070;
          }
        }
      }
    }
  }




  .viewchoose{
    display: flex !important;
  }
  .hidechoose{
    display: none !important;
  }



  .clickchoose{
    top: calc(100vh) !important;
  }
}