.devis {
  width: 450px;
  position: fixed;
  right: -450px;
  top: 0;
  height: 100%;
  z-index: 1000;
  background-color: white;
  border-left: 1px solid #b4b4b467;
  box-shadow: -1px 0px 5px rgba(180, 180, 180, 0.205);
  margin: 0;
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 70px;
  padding-right: 70px;

  .scroll {
    overflow: scroll;
    padding: 0;
    margin: 0;
    height: calc(100% - 100px);
    .titlezone {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: calc(0.2vw + 1.5rem);
        font-family: "Poppins";
        font-weight: 400;
        padding: 0;
        margin: 0;
      }

      .close {
        padding: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        div {
          border: 1px solid;
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            height: 14px;
            width: 14px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}

.titlesection {
  padding: 0;
  margin: 0;
  margin-top: calc(20px + 1vw);
  margin-bottom: calc(15px + 0.6vw);
  p {
    font-size: calc(0.2vw + 1.4rem);
    font-family: "Poppins";
    font-weight: 500;
    padding: 0;
    margin: 0;
  }
}

.infozone {
  padding: 0;
  margin: 0;
  margin-top: calc(22px + 1.5vw);
  .infoform {
    padding: 0;
    margin: 0;
    .oneinfo {
      padding: 0;
      margin: 0;
      margin-bottom: calc(7px + 0.3vw);
      label {
        width: 37%;
        font-size: calc(0.2vw + 1.2rem);
        font-family: "Poppins";
        font-weight: 400;
        span {
          color: red;
        }
      }
      input {
        width: 63%;
        min-width: 63%;
        width: auto;
        border: none;
        border-bottom: 1px solid rgba(190, 190, 190, 0.205);
        outline: none;

        font-size: calc(0.2vw + 0.9rem);
        font-family: "Poppins";
        font-weight: 400;
      }
      input:focus {
        border-bottom: 1px solid rgba(139, 150, 170, 0.411);
        background-color: white;
      }
    }
  }
}

.numberzone {
  padding: 0;
  margin: 0;
  .numberform {
    padding: 0;
    margin: 0;
    .number {
      padding: 0;
      margin: 0;
      input {
        border: 1px solid rgb(190, 190, 190);
        height: 30px;
        width: 60%;
        border-radius: 6px;
        outline: none;
      }
      input:focus {
        border: 1px solid rgba(124, 127, 134, 0.685);
      }
    }
  }
}

.recapzone {
  padding: 0;
  margin: 0;
  .recapform {
    padding: 0;
    margin: 0;
    .onerecap {
      padding: 0;
      margin: 0;
      margin-bottom: calc(7px + 0.2vw);
      .recapname {
        padding: 0;
        margin: 0;
        font-size: calc(0.2vw + 1.2rem);
        font-family: "Poppins";
        font-weight: 400;
      }
      div {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          padding: 0;
          padding-right: 15px;
          margin: 0;
          width: auto;
          border: none;
          font-size: calc(0.2vw + 1.2rem);
          font-family: "Poppins";
          font-weight: 400;
          display: flex;
          outline: none;
          background-image: url("../../../images/icon/arrow.svg");
          background-size: 10px;
          background-position-x: 100%;
          background-position-y: center;
          background-repeat: no-repeat;
        }
        p {
          padding: 0;
          padding-right: 45px;
          margin: 0;
          width: auto;
          border: none;
          font-size: calc(0.2vw + 1.2rem);
          font-family: "Poppins";
          font-weight: 400;
          display: flex;
          background-position-y: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.buttons {
  position: absolute;
  bottom: 0;
  height: 100px;
  padding: 0;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 26px;
  padding-top: 26px;
  margin: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-top: 1px solid rgb(245, 245, 245);
  box-shadow: 2px 2px 10px rgba(212, 212, 212, 0.13);
  background-color: white;
  .cancel {
    height: 40px;
    border: 1px solid rgb(54, 54, 54);
    background-color: white;
    width: 140px;
    font-size: calc(0.2vw + 1.2rem);
    font-family: "Poppins";
    font-weight: 600;
    color: rgb(54, 54, 54);
    outline: none;
  }
  .submit {
    height: 40px;
    border: 1px solid;
    background-color: rgb(100, 111, 133);
    width: 140px;
    border: none;
    color: white;
    font-size: calc(0.2vw + 1.2rem);
    font-family: "Poppins";
    font-weight: 600;
    outline: none;
  }
}

@keyframes devisnotvisible {
  0% {
    right: 0px;
  }
  100% {
    right: -450px;
  }
}
@keyframes devisvisible {
  0% {
    right: -450px;
  }
  100% {
    right: 0px;
  }
}
.divisvisible {
  right: 0px;
  animation: devisvisible 0.4s ease;
}
.devinotvisible {
  right: -450px;
  animation: devisnotvisible 0.2s ease;
}

.formulaire {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 3000;
}
.backgroundimg {
  background-image: url(../../../images/formulaire/bg-01.jpg);
}
