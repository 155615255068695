.allpage{
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  
  .container{
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
.content{
  height: 100%;
  padding: 0;
  padding-top: 26px;
  padding-bottom: 20px;
  margin: 0;
  display: block;
}
.infoentreprise{
  margin: 0;
  padding: 0;
  height: fit-content;
  width: 200px;
  .logoentreprise{
    margin: 0;
    padding: 0px;
  //  border: 1px solid rgba(170, 170, 170, 0.178);
    
    height: 40px;
    display: flex;
    align-items: center;
 //   justify-content: center;
    background-color: transparent;

    img{
     // width: 75%;
    //  min-width: 100px;
     // max-width: 198px;
     height: 90%;
  
    // filter: invert(1);
//     width: 110px;
     
      
    }
  }
}






.choose{
  position: absolute;
  top: 0px;
  z-index: 1;
  padding: 0;
  margin: 0;
  padding-left: calc(25px + 2.5vw);
  padding-right: calc(25px + 2.5vw);
  background-color: #F8F8F8;
  height: 60px;
  .choosetype{
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 0;
    margin: 0;
    padding-bottom: 0.6vh;
    padding-top: 20px;
    .type{
      height: 100%;
      display: flex;
      align-items: center;
      color: black;
      text-decoration: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      width: 33%;


      h2{
        font-family: 'Poppins';
        font-size: calc(0.3vw + 1.5rem);
        font-weight: 400;
        padding: 0;
        margin: 0;
        color: #9c9c9c;
      }
      h2:hover{
        font-weight: 500;
        color: black;
      }
      .selected{
        font-weight: 500;
        color: black;
      }
    }
    .type:nth-child(2n){
      justify-content: center;
    }
    .type:nth-child(3n){
      justify-content: flex-end;
    }
  }
}

@media (max-width: 576px) and (max-width: 992px)
{
  .choose{
    height: 20vh !important;
    left: -10px !important;
    flex: 0 0 110%;
    max-width: 110%;
    .choosetype{
      flex: 0 0 120%;
      max-width: 120%;
      .type{
        cursor: pointer;
        h2{
          font-size: calc(0.7vw + 1.4rem) !important;
        }
      }
    }
 }
}
@media (max-width: 576px)
{
  .choose{
      max-height: 100px;
      position: fixed;
      z-index: 10;
      padding: 0;
      width: 100%;
      margin: auto;
      bottom: 0;
      left: 0 !important;
      margin-bottom: 0 !important;
    .choosetype{
      padding: 0;
      padding-bottom: 0;
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      .type{
        flex: 0 0 33.333%;
        max-width: 33.333%;
        height: 100%;
        width: 33%;
        display: flex;
        align-items: center;
        color: black;
        text-decoration: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        h2{
          font-size: calc(0.7vw + 1.4rem) !important;
          text-align: center;
        }
      }
    }
 }
}
.contactzone{
  position: absolute;
  bottom: 0;
  height: 100px;
  @media (max-width: 1450px)
  {
    .contact{
      width: 170px !important;
    }
  }
  @media (max-width: 1200px)
    {
      .contact{
       width: 150px !important;
    }
  }
  @media (max-width: 992px)
  {
    .contact{
      left: -10px !important;
   }
  }
  .contact{
    height: max-content;
    border: 0.5px solid #707070;
    height: 40px;
    position: absolute;
    bottom: 30px;
    cursor: pointer;
    width: 200px;
    .contactus{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 1.4rem;
    }
    .contactus:hover{
      font-weight: 500;
    }
  }
}



@media (max-width: 576px)
{
  .infoentreprise{
    position: absolute;
    right: 25px;
    top: 55px;
    .logoentreprise{
      width: 100px;
      position: absolute;
      transform: rotate(270deg);
      /* flex: 0 0 100%; */
      max-width: fit-content;
      top: 0;
      left: 0;  
      img{
        height: 75%;
        margin: auto;
        -webkit-filter: invert(1);
        filter: invert(1);
        width: 70px;
      }
    }
 }
}
