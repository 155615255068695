:root{
  --bigsection: calc(50px + 7vh);
  --midsection: calc(25px + 2vh);

  --marginright: calc(20px + 1.9vh);

  --rightspace: 85%;
  --corpsspace: 70%;

  --titre: calc(20px + 2.5vw);
  --bigsoustitre: calc(13px + 1.35vw);
  --soustitre: calc(11px + 0.8vw);
  --corps: calc(9px + 0.6vw);
  --little: calc(8px + 0.5vw);
  --forminter: calc(9px + 0.4vw);
  --formlittle: calc(9px + 0.3vw);

  --titreli: calc(10px + 1vw);
}
@media (max-width: 768px){
  :root{
    --bigsection: calc(45px + 8.5vh);
    --midsection: calc(25px + 2vh);
  
    --marginright: calc(20px + 0.4vh);
  
    --rightspace: 100%;
    --corpsspace: 80%;

    --titre: calc(25px + 2.5vw);
    --bigsoustitre: calc(18px + 1.35vw);
    --soustitre: calc(17px + 1.2vw);
    --corps: calc(14px + 0.7vw);
    --little: calc(13px + 0.5vw);
    --forminter: calc(14px + 0.4vw);
    --formlittle: calc(14px + 0.3vw);
  }
}

.explications{
 height: 100%;
 width: 100%;
 margin: 0;
 padding: 0;
}

.navbar{
  position: relative;
  top: 0;
  height: 85px;
  background-color: white;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  z-index: 10;
  padding-left: 5.5%;
  img{
    height: 50px;
  }
  .mgi{
    height: 50px;
    margin-right: 50px;
    margin-left: -10px;
  }
  @media (max-width: 576px) { 
    height: 75px;
    .mgi{
      height: 38px;
      margin-right: 30px;
      margin-left: -10px;
    }
    img{
      height: 45px;
    }
  }
}

.divcontent{
  position: relative;
  height: calc(100vh - 80px);
  margin: 0;
  padding: 0;
  overflow: scroll;
  padding-left: 5.5%;
}

.content{
  margin: 0;
  padding: 0;
}
.logooscar{
  margin: 0;
  padding: 0;
  height: 90px;
  margin-top: calc(10px + 4vh);
  img{
    height: 100%;
  }
}

.logooscar{
  margin: 0;
  padding: 0;
  height: 90px;
  margin-top: calc(10px + 4vh);
  img{
    height: 80%;
  }
}

.titleprincipal{
  margin: 0;
  padding: 0;
  margin-top: calc(30px + 2vh);
  margin-bottom: -10px;
  h1{
    margin: 0;
    padding: 0;
    font-size: var(--titre);
    font-family: 'Work sans';
    font-weight: 600;
    display: flex;
    align-items: center;
    img{
      width: calc(200px);
    }
    }
  .contourbleu{
    margin: 0;
    padding: 0;

    height: 100%;
    display: flex;
    justify-content: center;
    
    h2{
      margin: 0;
      width: -moz-fit-content;
      width: fit-content;
      color: black;
      background-color: #ffffff;
      padding: 6px 15px 6px 0px;
      font-size: var(--soustitre);
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      color: rgb(80, 80, 80)
    }
  }
}

//TEXTE
.margintop{
//  margin-top: 20px !important;
}
.uniquespace{
  margin-bottom: calc(55px + 2.7vh) !important;
}
.oneexplication{
  margin: 0;
  padding: 0;
  margin-top: var(--bigsection);
  h3{
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-family: "Work sans";
    color: var(--blue);
    font-size: var(--soustitre);
    font-family: 'Work sans';
    font-weight: 700;
  }
  .soustitre{
    margin: 0;
    padding: 0;
    margin-top: calc(7px + 1.5vh);
    max-width: var(--rightspace);
    p{
      margin: 0;
      padding: 0;
      font-size: var(--soustitre);
      font-family: 'Poppins';
      font-weight: 600;
      ul{
        li{
          list-style: "-";
          .blue{
            color: var(--blue);
            font-size: var(--soustitre);
          }
        }
      }
    }

  }
  .width100{
    max-width: 100% !important;

  }
  .corps{
    margin: 0;
    padding: 0;
    margin-top: calc(5px + 0.2vh);
    max-width: var(--corpsspace);
    p{
      margin: 0;
      padding: 0;
      font-size: var(--corps);
      font-family: 'Work sans';
      font-weight: 500;
    }

  }
}
.bluetitre{
  text-transform: uppercase;
  font-family: "Work sans";
  color: var(--blue);
  font-size: var(--soustitre);
  font-family: 'Work sans';
  font-weight: 700 !important;
}
.corps{
  margin: 0;
  padding: 0;
  margin-top: var(--midsection);
  max-width: var(--corpsspace);
  p{
    margin: 0;
    padding: 0;
    font-size: var(--corps);
    font-family: 'Work sans';
    font-weight: 500;
  }

}
.bluetext{
  color: var(--blue);
}
//FIN TEXTE

//ICONES
.iconesection{
  margin: 0;
  padding: 0;
  margin-top: var(--midsection);
  .oneicon{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    div{
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      margin-right: var(--marginright);
      img{
        margin: 0;
        padding: 0;
        width: calc(45px + 0.7vw);
        margin-bottom: var(--little);

  
      }
    }
    img{
      margin: 0;
      padding: 0;
      width: calc(45px + 0.7vw);
      margin-right: var(--marginright);

    }
    p{
      margin: 0;
      padding: 0;
      font-size: var(--corps);
      font-family: 'Work sans';
      font-weight: 500;
      display: flex;
      justify-content: center;
      margin-right: var(--marginright);
    }
    .gras{
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

//FIN ICONE


//LISTE

.liste{
  margin: 0;
  padding: 0;
  margin-top: var(--midsection);
  ul{
    margin: 0;
    padding: 0;
    li{
      img{
        height: 20px;
        margin-right: calc(10px + 1.5vh);
      }
      margin: 0;
      padding: 0;
      list-style: square;
      font-size: var(--corps);
      line-height: calc(18px + 0.5vw);
      font-family: 'Work sans';
      font-weight: 600;
      margin-bottom: calc(15px + 0.3vh);
      display: flex;
      align-items: center;
      
    }
  }
  .imgpres{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;

    img{
      height: 51vh;
      max-width: 100%;

    }

    @media (min-height:830px){
      margin-top: -35px;
    }
    @media (max-width:576px){
      margin-top: 40px !important;
  
      img{
        height: auto !important;
      }
      justify-content: center !important;
    }

  }
  @media (max-width:1030px){
    padding-right: 0px;
  }

}
// Fin liste

// Video
.video{
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: var(--midsection);
  padding-right: 4.5%;
  display: flex;
  justify-content: center;
  img{
    width: 100%;
    height: intrinsic;
  }
  .intuitif{
    width: var(--rightspace);
  }
  
}
// FIN video



//FORMULAIRE

.footersection{
  margin: 0;
  padding: 0;
  margin-top: var(--bigsection);
  background-color: var(--blue);
  /* margin-left: -5.5%; */
  padding-left: 5.5%;
  padding-right: 5.5%;
  padding-top: calc(15px + 4.5%);
  padding-bottom: calc(15px + 4.5%);
  flex: 0 0 100%;
  max-width: 100%;
  color: white;
  position: absolute;
  left: 0;
  .topfooter{
    margin: 0;
    padding: 0;
    .left{
      margin: 0;
      padding: 0;
      h4{
        margin: 0;
        padding: 0;
        font-family: 'Poppins';
        font-size: var(--corps);
        font-weight: 500;
      }
      @media (max-width:768px){
        h4{
          margin-top: calc(10px);
          margin-bottom: calc(10px);
          text-align: center;
          font-size: var(--soustitre);
        }

      }
      form{
        margin: 0;
        padding: 0;
        margin-top: calc(15px + 4.5%);
        border: 1px solid white;
        padding: calc(15px + 4%);
        label{
          margin: 0;
          padding: 0;
          font-family: 'Work sans';
          font-size: var(--little);
          font-weight: 500;
          margin-top: calc(15px + 2%);
          margin-bottom: calc(15px + 2%);
          height: 35px;
          display: flex;
          align-items: center;
        }
        @media (max-width:768px){
          label{
            display: none;
            margin: 0;
            padding: 0;
            font-family: 'Work sans';
            font-size: var(--little);
            font-weight: 500;
            margin-top: calc(15px + 2%);
            margin-bottom: calc(15px + 2%);
            height: 35px;
          }
        }
        input{
          margin: 0;
          padding: 0;
          height: 35px;
          margin-top: calc(15px + 2%);
          margin-bottom: calc(15px + 2%);
          background-color: transparent;
          border: none;
          border-bottom: 1px solid white;
          text-align: center;
          color: white;
          font-family: 'Work sans';
          font-size: var(--formlittle);
          font-weight: 400;
          outline: none;

        }
        input::placeholder{

          color: rgba(255, 255, 255, 0.733);
          font-family: 'Work sans';
          font-size: var(--formlittle);
          font-weight: 400;

        }
        .agree{
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 15px;
          input{
            margin: 0;
            padding: 0;
            height: 17px;
            width: 17px;
            margin-right: 25px;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid white;
            text-align: center;
            color: white;
            font-family: 'Work sans';
            font-size: var(--formlittle);
            font-weight: 400;
            outline: none;
  
          }
          label{
            margin: 0;
            padding: 0;
            display: flex !important;
            flex-wrap: wrap;
            width: calc(100% - 45px);
            a{
              color: white;
              font-weight: 600;
              text-decoration: underline;
            }
  
          }

        }
        .submitzone{
          margin: 0;
          padding: 0;
          height: 35px;
          margin-top: calc(15px + 5%);
          margin-bottom: calc(15px + 2%);
          position: relative;
          img{
            margin: 0;
            padding: 0;
            height: 100%;
          }
          input{
            position: absolute;
            bottom: 0;
            right: 0;
            height: 30px;
            margin: 0;
            padding: 0;
            background-color: white;
            color: var(--blue);
            width: 150px;
            border-radius: 100px;
            border: none;
          }
          input:hover{
            background-color: rgb(29, 29, 29);
            color: white;
            transition: 0.2s;
            transform: scale(1.05);
          }
        }
      }
    }
    .right{
      margin: 0;
      padding: 0;
      display: flex;
      align-content: space-between;
      justify-content: flex-end;
        padding-left: 15px;
      img{
        width: 75%;
        max-height: 40px;
        min-height: 30px;
        padding: 0;
        margin: 0;
      }
      p{
        margin: 0;
        padding: 0;
        font-family: 'Work sans';
        font-size: var(--forminter);
        font-weight: 400;
        width: 100%;
        color: white;
      }
    }
    @media (max-width: 768px){
      .right{
        margin-top: 35px;
        margin-bottom: 35px;
        display: flex;
        align-content: center;
        justify-content: flex-end;
        img{
          max-height: 20px;
          min-height: 25px;
          position: absolute;
          margin-left: -15px;
          left: 0;
        }
        p{
          margin: 0;
          padding: 0;
          font-family: 'Work sans';
          font-size: var(--forminter);
          font-weight: 400;
          width: 75%;
        }
      }
    }
  }

  .bottom{
    margin: 0;
    padding: 0;
    padding-top: 7%;
    .copyright{
      margin: 0;
      padding: 0;
      p{
        font-family: 'Work sans';
        font-size: var(--formlittle);
        font-weight: 400;
        color: white;
        a{
          color: white;
          text-decoration: none;
        }
        a:hover{
          color: rgb(0, 0, 0);
          text-decoration: none;
          transition: 0.2s;
        }
      }
    }
    .logo{    
      margin: 0;
      padding: 0;
      display: flex;
      align-content: center;
      justify-content: flex-end;
      a{
        margin: 0;
        padding: 0;
        height: 18px;
        margin-left: calc(15px + 0.4vw);;
        img{
          height: 100%;
        }
      }
      a:hover{
        filter: invert(1);
        transition: 0.2s;
      }
    }
  }
}
//FIN FORMULAIRE


// ___________________________________________________

//AROW

.arrow{
  margin: 0;
  padding: 0;
  margin-top: var(--midsection);
  p{
    margin: 0;
    padding: 0;
    font-size: var(--corps);
    font-family: 'Work sans';
    font-weight: 500;
    color: var(--blue);
    text-transform: uppercase;
  }
  
}

.arrowmobilezone{
  margin: 0;
  padding: 0;
  margin-top: var(--midsection);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5.5%;

.arrowmobile{
  margin: 0;
  padding: 0;

  border: 1px solid black;
  border-radius: 30px;
  height: 40px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;

  p{
    margin: 0;
    padding: 0;
    font-size: var(--little);
    font-family: 'Work sans';
    font-weight: 500;
    color: #000000;
    text-transform: uppercase;
  }
}
}
@media (max-width:768px){
  .arrow{
    display: none;
  }
}
@media (min-width:768px){
  .arrowmobilezone{
    display: none;
  }
}


.wrapperinner {
  display: table-cell;
  vertical-align:middle;
width: 100%;
  margin-left: 50px;
}

.scrolldown {
    display: block;
    position: relative;
    padding-top: 0px;
  text-align:center;
}
.arrowdown {
    display: block;
    margin: 0 auto;
    width: 40vw;
}
.arrowdown:after {
    content: '';
    display: block;
    margin: 0;
    padding: 0;
    width: 15px;
    height: 15px;
    border-top: 4px solid var(--blue);
    border-right: 4px solid var(--blue);
   // behavior: url(-ms-transform.htc);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: calc(40vw - 15px);
}

.scrolldown::before {
    -webkit-animation: elasticus 2.2s cubic-bezier(1, 0, 0, 1) infinite;
    /* Safari 4+ */
    
    -moz-animation: elasticus 2.2s cubic-bezier(1, 0, 0, 1) infinite;
    /* Fx 5+ */
    
    -o-animation: elasticus 2.2s cubic-bezier(1, 0, 0, 1) infinite;
    /* Opera 12+ */
    
    animation: elasticus 2.2s cubic-bezier(1, 0, 0, 1) infinite;
    /* IE 10+, Fx 29+ */
    
    position: absolute;
    top: 0px;
    left: 0%;
    margin-left: 0px;
    margin-top: 5px;
    width: 100%;
    height: 4px;
    background: var(--blue);
    content: ' ';
}
@-webkit-keyframes elasticus {
    0% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(0, 1);
        -ms-transform: scale(0, 1);
        -moz-transform: scale(0, 1);
        -o-transform: scale(0, 1);
        transform: scale(0, 1);
    }
    50% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50.1% {
        -webkit-transform-origin: 100% 0%;
        -ms-transform-origin: 100% 0%;
        -moz-transform-origin: 100% 0%;
        -o-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    100% {
      -webkit-transform-origin: 100% 0%;
      -ms-transform-origin: 100% 0%;
      -moz-transform-origin: 100% 0%;
      -o-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
        -webkit-transform: scale(0, 1);
        -ms-transform: scale(0, 1);
        -moz-transform: scale(0, 1);
        -o-transform: scale(0, 1);
        transform: scale(0, 1);
    }
}
@-moz-keyframes elasticus {
    0% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(0, 1);
        -ms-transform: scale(0, 1);
        -moz-transform: scale(0, 1);
        -o-transform: scale(0, 1);
        transform: scale(0, 1);
    }
    50% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50.1% {
      -webkit-transform-origin: 100% 0%;
      -ms-transform-origin: 100% 0%;
      -moz-transform-origin: 100% 0%;
      -o-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    100% {
      -webkit-transform-origin: 100% 0%;
      -ms-transform-origin: 100% 0%;
      -moz-transform-origin: 100% 0%;
      -o-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
        -webkit-transform: scale(0, 1);
        -ms-transform: scale(0, 1);
        -moz-transform: scale(0, 1);
        -o-transform: scale(0, 1);
        transform: scale(0, 1);
    }
}
@-o-keyframes elasticus {
    0% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(0, 1);
        -ms-transform: scale(0, 1);
        -moz-transform: scale(0, 1);
        -o-transform: scale(0, 1);
        transform: scale(0, 1);
    }
    50% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50.1% {
      -webkit-transform-origin: 100% 0%;
      -ms-transform-origin: 100% 0%;
      -moz-transform-origin: 100% 0%;
      -o-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    100% {
      -webkit-transform-origin: 100% 0%;
      -ms-transform-origin: 100% 0%;
      -moz-transform-origin: 100% 0%;
      -o-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
        -webkit-transform: scale(0, 1);
        -ms-transform: scale(0, 1);
        -moz-transform: scale(0, 1);
        -o-transform: scale(0, 1);
        transform: scale(0, 1);
    }
}
@keyframes elasticus {
    0% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(0, 1);
        -ms-transform: scale(0, 1);
        -moz-transform: scale(0, 1);
        -o-transform: scale(0, 1);
        transform: scale(0, 1);
    }
    50% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50.1% {
      -webkit-transform-origin: 100% 0%;
      -ms-transform-origin: 100% 0%;
      -moz-transform-origin: 100% 0%;
      -o-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    100% {
      -webkit-transform-origin: 100% 0%;
      -ms-transform-origin: 100% 0%;
      -moz-transform-origin: 100% 0%;
      -o-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
        -webkit-transform: scale(0, 1);
        -ms-transform: scale(0, 1);
        -moz-transform: scale(0, 1);
        -o-transform: scale(0, 1);
        transform: scale(0, 1);
    }
}


// SCROLL

.demo{
  top: 85%;
  left: calc(50% - 35px);
  position: absolute;
  height: 40px;
  width: 30px;
  filter: invert(1);
  z-index: 2000;
  height: 80px;
  width: 80px;
  border: 1px solid #151515;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000e6;
}
@media (max-width: 768px){
  .demo{
    display: none;
  }

}
.demo a {
  position: absolute;
  bottom:0px;
 // left: 50%;
  z-index: 20;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font-family: 'Work sans';
  font-weight: 500;
  font-size: var(--little);
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
  text-transform: uppercase;
  color: #797979;
}
.demo a:hover {
 // opacity: .5;
 cursor: default;
}
.section05 a {
//  padding-top: 70px;
}
.section05 a span {
  position: absolute;
  top: -70px;

  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;

}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}